import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, FormControlLabel, Switch, CircularProgress } from '@mui/material';
import { useProperty } from '../context/DataContext';
import axios from 'axios';
import { format, subMonths } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import CanvasJSReact from '../lib/canvasjs.react';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../components/SubmitButton'; // Import the SubmitButton component

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
const adminEmails = process.env.REACT_APP_ADMIN_EMAILS ? process.env.REACT_APP_ADMIN_EMAILS.split(',') : [];

const ValuationReport = () => {
  const { t } = useTranslation();
  const { propertyData } = useProperty();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [debug, setDebug] = useState(false);
  const [loadingTrend, setLoadingTrend] = useState(false);
  const [loadingFireSaleComparison, setLoadingFireSaleComparison] = useState(false);
  const [loadingInsights, setLoadingInsights] = useState(false);
  const [valuationResponses, setValuationResponses] = useState([]);
  const [fireSaleComparisonResponses, setFireSaleComparisonResponses] = useState([]);
  const [insightsResponses, setInsightsResponses] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDebugToggle = () => setDebug(!debug);

  const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
      let propName = parent ? `${parent}_${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  const handleValuation = async () => {
    setLoadingTrend(true);
    const dates = [];
    for (let i = 0; i <= 12; i++) {
      dates.push({
        label: i === 0 ? 'Current Date' : `${i} Quarter${i > 1 ? 's' : ''} Ago`,
        date: subMonths(new Date(), i * 3)
      });
    }

    try {
      const flattenedPropertyData = flattenObject(propertyData);
      const responses = await Promise.all(
        dates.map(async ({ label, date }) => {
          const adPostedDate = format(date, 'yyyy-MM-dd');
          const updatedData = { ...flattenedPropertyData, ad_posted_date: adPostedDate };
          const response = await axios.post('https://valuationapi.revaluer.app/predict_property_value', updatedData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const result = response.data[0];
          return { label, date: adPostedDate, data: result };
        })
      );
      setValuationResponses(responses);
    } catch (error) {
      console.error('Error fetching valuation:', error);
    }
    setLoadingTrend(false);
  };

  const handleFireSaleComparison = async () => {
    setLoadingFireSaleComparison(true);
    const fireSaleOptions = [false, true];

    try {
      const flattenedPropertyData = flattenObject(propertyData);
      const responses = await Promise.all(
        fireSaleOptions.map(async (fireSale) => {
          const updatedData = { ...flattenedPropertyData, fire_sale: fireSale };
          const response = await axios.post('https://valuationapi.revaluer.app/predict_property_value', updatedData, {
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const result = response.data[0];
          return { fireSale, data: result };
        })
      );
      setFireSaleComparisonResponses(responses);
    } catch (error) {
      console.error('Error fetching fire sale comparison:', error);
    }
    setLoadingFireSaleComparison(false);
  };

  const handleValuationInsights = async () => {
    setLoadingInsights(true);
    try {
      const flattenedPropertyData = flattenObject(propertyData);

      const response = await axios.post('https://valuationapi.revaluer.app/calculate_shap_values', flattenedPropertyData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const responseData = response.data;

      // Store the API response in the state
      setInsightsResponses(responseData);

    } catch (error) {
      console.error('Error fetching valuation insights:', error);
    }
    setLoadingInsights(false);
  };

  const valuationChartData = valuationResponses.map((response) => ({
    x: new Date(response.date),
    y: response.data.transaction_adjusted_value_per_m2,
  })).reverse(); // Reverse to show newer dates to the right

  const fireSaleComparisonChartData = fireSaleComparisonResponses.map((response) => ({
    label: response.fireSale ? 'Fire Sale' : 'Normal Sale',
    y: response.data.transaction_adjusted_value,
  }));

  const groupShapValuesChartData = insightsResponses ? Object.entries(insightsResponses.group_shap_values)
    .filter(([key]) => key !== 'Excluded from Valuation')
    .map(([key, value]) => ({ label: key, y: value }))
    .sort((a, b) => b.y - a.y) : [];

  return (
    <Container maxWidth="sm" className="container">
      <Typography variant="h5" component="h2" gutterBottom>
        {t('valuationReport.title')}
      </Typography>
      <Box className="mt-4" display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={handleValuation}
          disabled={loadingTrend}
          className="button"
        >
          {t('valuationReport.trendAnalysis')}
        </Button>
        {loadingTrend && <CircularProgress size={24} className="ml-2" />}
      </Box>
      <Box className="mt-4" display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          color="warning"
          onClick={handleFireSaleComparison}
          disabled={loadingFireSaleComparison}
          className="button"
        >
          {t('valuationReport.fireSaleComparison')}
        </Button>
        {loadingFireSaleComparison && <CircularProgress size={24} className="ml-2" />}
      </Box>
      <Box className="mt-4" display="flex" justifyContent="center" alignItems="center">
        <Button
          variant="contained"
          color="success"
          onClick={handleValuationInsights}
          disabled={loadingInsights}
          className="button"
        >
          {t('valuationReport.valuationInsights')}
        </Button>
        {loadingInsights && <CircularProgress size={24} className="ml-2" />}
      </Box>
      {valuationResponses.length > 0 && (
        <Box className="mt-4">
          <CanvasJSChart options={{
            animationEnabled: true,
            theme: "light2",
            title: {
              text: t('valuationReport.trendAnalysis')
            },
            axisX: {
              valueFormatString: "MMM YYYY"
            },
            axisY: {
              title: t('valuationReport.estimatedValuePerM2'),
              prefix: "€",
              minimum: Math.min(...valuationChartData.map(d => d.y)) - 100,
              maximum: Math.max(...valuationChartData.map(d => d.y)) + 100
            },
            data: [{
              type: "spline",
              dataPoints: valuationChartData
            }]
          }} />
        </Box>
      )}
      {fireSaleComparisonResponses.length > 0 && (
        <Box className="mt-4">
          <CanvasJSChart options={{
            animationEnabled: true,
            theme: "light2",
            title: {
              text: t('valuationReport.fireSaleComparison')
            },
            axisX: {
              title: t('valuationReport.saleType')
            },
            axisY: {
              title: t('valuationReport.transactionValue'),
              prefix: "€"
            },
            data: [{
              type: "column",
              yValueFormatString: "€#,##0.00",
              dataPoints: fireSaleComparisonChartData
            }]
          }} />
        </Box>
      )}
      {insightsResponses && (
        <>
          <Box className="mt-4">
            <CanvasJSChart options={{
              animationEnabled: true,
              theme: "light2",
              title: {
                text: t('valuationReport.featureStrengthsAndWeaknessesAnalysis')
              },
              axisX: {
                title: t('valuationReport.groups')
              },
              axisY: {
                title: t('valuationReport.relativeToAverage'),
                stripLines: [{
                  value: 1,
                  color: "red",
                  label: t('valuationReport.average'),
                  labelFontColor: "red",
                  labelAlign: "far",
                  showOnTop: true
                }]
              },
              data: [{
                type: "bar",
                dataPoints: groupShapValuesChartData
              }]
            }} />
          </Box>
          <Box className="mt-4">
            <CanvasJSChart options={{
              animationEnabled: true,
              theme: "light2",
              title: {
                text: t('valuationReport.valueAdjustmentsBreakdown')
              },
              axisX: {
                title: t('valuationReport.adjustments'),
                labelAngle: -45,
                labelFontSize: 12,
                labelMaxWidth: 80,
                labelWrap: false,
                interval: 1
              },
              axisY: {
                title: t('valuationReport.value')
              },
              data: [{
                type: "waterfall",
                yValueFormatString: "$#,##0.00",
                indexLabelOrientation: "vertical",
                dataPoints: insightsResponses.waterfall_data_points
              }]
            }} />
          </Box>
        </>
      )}
      {user && adminEmails.includes(user.email) && (
        <Box className="mt-4">
          <FormControlLabel
            control={<Switch checked={debug} onChange={handleDebugToggle} color="primary" />}
            label={t('valuationReport.toggleDebugInformation')}
          />
        </Box>
      )}
      {debug && (
        <Box className="mt-2" style={{ textAlign: 'left', wordWrap: 'break-word' }}>
          <Typography variant="h6">{t('valuationReport.debugData')}</Typography>
          <pre>{JSON.stringify(propertyData, null, 2)}</pre>
          {insightsResponses && (
            <>
              <Typography variant="h6">{t('valuationReport.apiResponseData')}</Typography>
              <pre>{JSON.stringify(insightsResponses, null, 2)}</pre>
            </>
          )}
        </Box>
      )}
      <Box className="mt-4">
        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
          {t('valuationReport.back')}
        </Button>
      </Box>
      <Box mt={4} textAlign="center">
        {user && adminEmails.includes(user.email) && (
          <SubmitButton t={t} />
        )}
      </Box>
    </Container>
  );
};

export default ValuationReport;