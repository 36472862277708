import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Grid, ToggleButton, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useProperty } from '../context/DataContext';
import ScrollToTop from '../components/ScrollToTop';
import { useTranslation } from 'react-i18next';

const Amenities = () => {
  const { t } = useTranslation();
  const { propertyData, updatePropertyData } = useProperty();
  const navigate = useNavigate();

  const commonAmenities = ['playroom', 'wheelchair_ramp'];

  const amenities = {
    Apartment: {
      'For Rent': [
        'backyard_access',
        'basement_storage',
        'fitness_center_in_building',
        'reception_or_security',
        'swimming_pool',
      ],
      'For Sale': [
        'backyard_access',
        'basement_storage',
        'fitness_center_in_building',
        'reception_or_security',
        'swimming_pool',
      ],
    },
    House: {
      'For Rent': [
        'additional_basement_rooms',
        'backyard_access',
        'billiards_room',
        'gym_in_house',
        'indoor_pool',
        'outdoor_pool',
        'sauna',
        'staff_quarters',
        'study_room',
      ],
      'For Sale': [
        'additional_basement_rooms',
        'billiards_room',
        'gym_in_house',
        'indoor_pool',
        'outdoor_pool',
        'sauna',
        'staff_quarters',
        'study_room',
        'wine_cellar',
      ],
    },
  };

  const propertyType = propertyData.property_type;
  const transactionType = propertyData.transaction_type;

  const [selectedAmenities, setSelectedAmenities] = useState({});

  useEffect(() => {
    const initialAmenities = commonAmenities.reduce((acc, amenity) => {
      acc[amenity] = propertyData[amenity] || false;
      return acc;
    }, {});

    if (propertyType && transactionType && amenities[propertyType] && amenities[propertyType][transactionType]) {
      const specificAmenities = amenities[propertyType][transactionType].reduce((acc, amenity) => {
        acc[amenity] = propertyData[amenity] || false;
        return acc;
      }, initialAmenities);
      setSelectedAmenities(specificAmenities);
    } else {
      setSelectedAmenities(initialAmenities);
    }
  }, [propertyType, transactionType, propertyData]);

  const handleToggleChange = (amenity) => {
    const newSelectedAmenities = { ...selectedAmenities, [amenity]: !selectedAmenities[amenity] };
    setSelectedAmenities(newSelectedAmenities);
    updatePropertyData({ [amenity]: newSelectedAmenities[amenity] });
  };

  const handleNextClick = () => {
    navigate('/parking-options');
  };

  return (
    <Container maxWidth="md" className="container">
      <ScrollToTop /> {/* Scroll to top on component load */}
      <Button onClick={() => navigate(-1)} variant="contained" color="secondary" style={{ marginBottom: '10px' }}>
        {t('amenities.back')}
      </Button>
      <Typography variant="h5" component="h2" gutterBottom>
        {t('amenities.title')}
      </Typography>
      <Box mt={4}>
        <Typography variant="subtitle1">{t('amenities.selectAmenities')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {commonAmenities.map((amenity) => (
            <Grid item key={amenity} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={amenity}
                selected={selectedAmenities[amenity]}
                className={`toggle-button ${selectedAmenities[amenity] ? 'selected' : ''}`}
                onClick={() => handleToggleChange(amenity)}
                sx={{ width: '100%' }}
              >
                {t(`amenities.amenities.${amenity}`)}
              </ToggleButton>
            </Grid>
          ))}
          {propertyType &&
            transactionType &&
            amenities[propertyType] &&
            amenities[propertyType][transactionType] &&
            amenities[propertyType][transactionType].map((amenity) => (
              <Grid item key={amenity} sx={{ mr: 1, mb: 1 }}>
                <ToggleButton
                  value={amenity}
                  selected={selectedAmenities[amenity]}
                  className={`toggle-button ${selectedAmenities[amenity] ? 'selected' : ''}`}
                  onClick={() => handleToggleChange(amenity)}
                  sx={{ width: '100%' }}
                >
                  {t(`amenities.amenities.${amenity}`)}
                </ToggleButton>
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box mt={6}>
        <Button variant="contained" color="primary" onClick={handleNextClick}>
          {t('amenities.next')}
        </Button>
      </Box>
    </Container>
  );
};

export default Amenities;