import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  FormControlLabel,
  Switch,
  ToggleButton,
  Grid,
  TextField,
  Button,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useProperty } from '../context/DataContext';
import ScrollToTop from '../components/ScrollToTop'; // Make sure to update the path if needed
import '../styles/global.css';
import { useTranslation } from 'react-i18next';

const PositionOrientationView = () => {
  const { t } = useTranslation();
  const { propertyData, updatePropertyData } = useProperty();
  const navigate = useNavigate();
  const [floor, setFloor] = useState(propertyData.floor || '');
  const [customFloor, setCustomFloor] = useState('');
  const [stories, setStories] = useState(propertyData.stories || '');
  const [customStories, setCustomStories] = useState('');
  const [hasElevator, setHasElevator] = useState(Boolean(propertyData.has_elevator));
  const [orientation, setOrientation] = useState({
    facing_east: Boolean(propertyData.facing_east),
    facing_north: Boolean(propertyData.facing_north),
    facing_south: Boolean(propertyData.facing_south),
    facing_west: Boolean(propertyData.facing_west),
  });
  const [view, setView] = useState({
    greenery_view: Boolean(propertyData.greenery_view),
    mountain_view: Boolean(propertyData.mountain_view),
    panoramic_city_view: Boolean(propertyData.panoramic_city_view),
    park_view: Boolean(propertyData.park_view),
    water_view: Boolean(propertyData.water_view),
    other_view: Boolean(propertyData.other_view),
  });
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');

  useEffect(() => {
    const floorValue = customFloor || floor;
    const storiesValue = customStories || stories;
    if (floorValue && storiesValue) {
      const topFloor = Number(floorValue) === Number(storiesValue);
      if (propertyData.top_floor !== topFloor) {
        updatePropertyData({ top_floor: topFloor });
      }
    }
  }, [floor, customFloor, stories, customStories, propertyData.top_floor, updatePropertyData]);

  const getTopFloorOptions = () => {
    let options = [];
    const floorValue = customFloor || floor;

    if (floor === '20+' || customFloor) {
      const value = Number(customFloor) || 20;
      for (let i = value; i <= value + 19; i++) {
        options.push(i.toString());
      }
      options.push(`${value + 20}+`);
    } else if (floorValue === 0.5) {
      options = ['1', ...Array.from({ length: 19 }, (_, i) => (i + 2).toString()), '21+'];
    } else {
      const startValue = floorValue <= 0 ? 0 : Number(floorValue);
      for (let i = startValue; i < startValue + 20; i++) {
        options.push(i.toString());
      }
      options.push(`${startValue + 20}+`);
    }

    return options;
  };

  const validateCustomValues = () => {
    const floorValue = Number(customFloor || floor);
    const storiesValue = Number(customStories || stories);
    if (floorValue > storiesValue) {
      setError(t('positionOrientationView.error.floorGreaterThanStories'));
      return false;
    }
    if ((floor === '20+' && floorValue < 20) || (stories === '20+' && storiesValue < 20) || (stories === '5+' && storiesValue < 5)) {
      setWarning(t('positionOrientationView.warning.minimumValue'));
      return false;
    }
    setError('');
    setWarning('');
    return true;
  };

  const handleFloorChange = (floorOption) => {
    setFloor(floorOption);
    updatePropertyData({ floor: floorOption });
    if (floorOption !== '20+') {
      setCustomFloor('');
      updatePropertyData({ custom_floor: '' });
    }
  };

  const handleTopFloorChange = (story) => {
    setStories(story);
    updatePropertyData({ stories: story });
    if (story.endsWith('+')) {
      setCustomStories('');
      updatePropertyData({ custom_stories: '' });
    }
  };

  const handleNextClick = () => {
    if (!validateCustomValues()) return;

    const floorValue = customFloor || floor;
    const storiesValue = customStories || stories;
    const topFloor = Number(floorValue) === Number(storiesValue);

    updatePropertyData({
      stories: storiesValue,
      floor: floorValue,
      top_floor: topFloor,
      has_elevator: hasElevator,
      ...orientation,
      ...view,
    });
    navigate('/property-condition');
  };

  const handleOrientationChange = (direction) => {
    const newOrientation = { ...orientation, [direction]: !orientation[direction] };
    setOrientation(newOrientation);
    updatePropertyData(newOrientation);
  };

  const handleViewChange = (viewType) => {
    const newView = { ...view, [viewType]: !view[viewType] };
    setView(newView);
    updatePropertyData(newView);
  };

  return (
    <Container maxWidth="md" className="container">
      <ScrollToTop /> {/* Scroll to top on component load */}
      <Button onClick={() => navigate(-1)} variant="contained" color="secondary" style={{ marginBottom: '10px' }}>{t('positionOrientationView.back')}</Button>
      {propertyData.property_type === 'Apartment' && (
        <>
          <Box className="mt-2">
            <Typography variant="h5">{t('positionOrientationView.floor')}</Typography>
            <Grid container spacing={1} justifyContent="center">
              {[
                { label: t('positionOrientationView.floorOptions.Basement'), value: -1 },
                { label: t('positionOrientationView.floorOptions.Lower ground floor'), value: -0.5 },
                { label: t('positionOrientationView.floorOptions.Ground floor'), value: 0 },
                { label: t('positionOrientationView.floorOptions.Mezzanine'), value: 0.5 },
                ...Array.from({ length: 19 }, (_, i) => ({ label: t('positionOrientationView.floorOptions.Floor {{floor}}', { floor: i + 1 }), value: i + 1 })),
                { label: t('positionOrientationView.floorOptions.20+'), value: '20+' }
              ].map((floorOption) => (
                <Grid item key={floorOption.value} sx={{ mr: 1, mb: 1 }}>
                  <ToggleButton
                    value={floorOption.value}
                    selected={floor === floorOption.value}
                    className={`toggle-button ${floor === floorOption.value ? 'selected' : ''}`}
                    onClick={() => handleFloorChange(floorOption.value)}
                    sx={{ width: '100%' }}
                  >
                    {floorOption.label}
                  </ToggleButton>
                </Grid>
              ))}
            </Grid>
            {floor === '20+' && (
              <Box className="mt-4">
                <TextField
                  label={t('positionOrientationView.enterFloorNumber')}
                  type="number"
                  fullWidth
                  value={customFloor}
                  onChange={(e) => {
                    const value = e.target.value;
                    setCustomFloor(value);
                    updatePropertyData({ custom_floor: value });
                  }}
                  margin="normal"
                  inputProps={{ min: 20 }}
                />
              </Box>
            )}
          </Box>
          <Box className="mt-4">
            <Typography variant="h5">{t('positionOrientationView.topFloor')}</Typography>
            <Grid container spacing={1} justifyContent="center">
              {getTopFloorOptions().map((story) => (
                <Grid item key={story} sx={{ mr: 1, mb: 1 }}>
                  <ToggleButton
                    value={story}
                    selected={stories === story}
                    className={`toggle-button ${stories === story ? 'selected' : ''}`}
                    onClick={() => handleTopFloorChange(story)}
                    sx={{ width: '100%' }}
                  >
                    {story === '0' ? t('positionOrientationView.singleStory') : story}
                  </ToggleButton>
                </Grid>
              ))}
            </Grid>
            {stories.endsWith('+') && (
              <Box className="mt-4">
                <TextField
                  label={t('positionOrientationView.enterTopFloorNumber')}
                  type="number"
                  fullWidth
                  value={customStories}
                  onChange={(e) => {
                    const value = e.target.value;
                    setCustomStories(value);
                    updatePropertyData({ custom_stories: value });
                  }}
                  margin="normal"
                  inputProps={{ min: Number(customFloor || floor) }}
                />
              </Box>
            )}
          </Box>
        </>
      )}
      {propertyData.property_type === 'House' && (
        <Box className="mt-4">
          <Typography variant="h5">{t('positionOrientationView.stories')}</Typography>
          <Grid container spacing={1} justifyContent="center">
            {[
              { label: t('positionOrientationView.singleStory'), value: '0' },
              ...Array.from({ length: 4 }, (_, i) => ({ label: (i + 1).toString(), value: (i + 1).toString() })),
              { label: '5+', value: '5+' }
            ].map((story) => (
              <Grid item key={story.value} sx={{ mr: 1, mb: 1 }}>
                <ToggleButton
                  value={story.value}
                  selected={stories === story.value}
                  className={`toggle-button ${stories === story.value ? 'selected' : ''}`}
                  onClick={() => handleTopFloorChange(story.value)}
                  sx={{ width: '100%' }}
                >
                  {story.label}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
          {stories === '5+' && (
            <Box className="mt-4">
              <TextField
                label={t('positionOrientationView.enterNumberOfStories')}
                type="number"
                fullWidth
                value={customStories}
                onChange={(e) => {
                  const value = e.target.value;
                  setCustomStories(value);
                  updatePropertyData({ custom_stories: value });
                }}
                margin="normal"
                inputProps={{ min: 5 }}
              />
            </Box>
          )}
        </Box>
      )}
      <Box className="mt-4">
        <FormControlLabel
          control={<Switch
            checked={hasElevator}
            onChange={(e) => {
              setHasElevator(e.target.checked);
              updatePropertyData({ has_elevator: e.target.checked });
            }} color="primary" />}
          label={t('positionOrientationView.hasElevator')}
        />
      </Box>
      {propertyData.property_type === 'Apartment' && (
        <Box className="mt-4">
          <Typography variant="h5">{t('positionOrientationView.orientation')}</Typography>
          <Grid container spacing={1} justifyContent="center">
            {['facing_east', 'facing_north', 'facing_south', 'facing_west'].map((direction) => (
              <Grid item key={direction} sx={{ mr: 1, mb: 1 }}>
                <ToggleButton
                  value={direction}
                  selected={orientation[direction]}
                  className={`toggle-button ${orientation[direction] ? 'selected' : ''}`}
                  onClick={() => handleOrientationChange(direction)}
                  sx={{ width: '100%' }}
                >
                  {t(`positionOrientationView.orientationOptions.${direction}`)}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Box className="mt-4">
        <Typography variant="h5">{t('positionOrientationView.view')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {['greenery_view', 'mountain_view', 'panoramic_city_view', 'park_view', 'water_view', 'other_view'].map((viewType) => (
            <Grid item key={viewType} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={viewType}
                selected={view[viewType]}
                className={`toggle-button ${view[viewType] ? 'selected' : ''}`}
                onClick={() => handleViewChange(viewType)}
                sx={{ width: '100%' }}
              >
                {t(`positionOrientationView.viewOptions.${viewType}`)}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      {warning && (
        <Box mt={2}>
          <Alert severity="warning">{warning}</Alert>
        </Box>
      )}
      {error && (
        <Box mt={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={handleNextClick}>
          {t('positionOrientationView.next')}
        </Button>
      </Box>
    </Container>
  );
};

export default PositionOrientationView;