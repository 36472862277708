import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, ToggleButton, ToggleButtonGroup, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useProperty } from '../context/DataContext';
import ScrollToTop from '../components/ScrollToTop'; // Make sure to update the path if needed
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import '../styles/global.css';

const PropertyType = () => {
  const { t } = useTranslation(); // Initialize the translation hook
  const { propertyData, updatePropertyData } = useProperty();
  const navigate = useNavigate();
  const [propertyType, setPropertyType] = useState(propertyData.property_type || '');
  const [transactionType, setTransactionType] = useState(propertyData.transaction_type || '');
  const [pricePeriod, setPricePeriod] = useState(propertyData.price_period || '');

  const handlePropertyTypeChange = (value) => {
    if (value) {
      setPropertyType(value);
      updatePropertyData({ property_type: value });
    }
  };

  const handleTransactionTypeChange = (value) => {
    if (value) {
      setTransactionType(value);
      setPricePeriod(''); // Reset price period when transaction type changes
      updatePropertyData({ transaction_type: value });
    }
  };

  const handlePricePeriodChange = (value) => {
    if (value) {
      setPricePeriod(value);
      updatePropertyData({ price_period: value });
    }
  };

  const handleNextClick = () => {
    if (propertyType && transactionType) {
      updatePropertyData({
        property_type: propertyType,
        transaction_type: transactionType,
        price_period: transactionType === 'For Rent' ? pricePeriod : null,
      });
      navigate('/address-input');
    } else {
      alert(t('propertyType.alert'));
    }
  };

  return (
    <Container maxWidth="sm" className="container">
      <ScrollToTop /> {/* Scroll to top on component load */}
      <Box mt={2} mb={1}>
        <Typography variant="h5" component="h2">
          {t('propertyType.choosePropertyType')}
        </Typography>
      </Box>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={propertyType}
            exclusive
            onChange={(e, value) => handlePropertyTypeChange(value)}
            aria-label="property type"
            fullWidth
          >
            <ToggleButton
              value="Apartment"
              className={`toggle-button ${propertyType === 'Apartment' ? 'selected' : ''}`}
            >
              {t('propertyType.apartment')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={propertyType}
            exclusive
            onChange={(e, value) => handlePropertyTypeChange(value)}
            aria-label="property type"
            fullWidth
          >
            <ToggleButton
              value="House"
              className={`toggle-button ${propertyType === 'House' ? 'selected' : ''}`}
            >
              {t('propertyType.house')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      <Box mt={2} mb={1}>
        <Typography variant="h5" component="h2">
          {t('propertyType.chooseTransactionType')}
        </Typography>
      </Box>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={transactionType}
            exclusive
            onChange={(e, value) => handleTransactionTypeChange(value)}
            aria-label="transaction type"
            fullWidth
          >
            <ToggleButton
              value="For Sale"
              className={`toggle-button ${transactionType === 'For Sale' ? 'selected' : ''}`}
            >
              {t('propertyType.forSale')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={6}>
          <ToggleButtonGroup
            value={transactionType}
            exclusive
            onChange={(e, value) => handleTransactionTypeChange(value)}
            aria-label="transaction type"
            fullWidth
          >
            <ToggleButton
              value="For Rent"
              className={`toggle-button ${transactionType === 'For Rent' ? 'selected' : ''}`}
            >
              {t('propertyType.forRent')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      {transactionType === 'For Rent' && (
        <>
          <Box mt={2} mb={1}>
            <Typography variant="h5" component="h2">
              {t('propertyType.chooseRentPeriod')}
            </Typography>
          </Box>
          <Grid container spacing={2} className="mt-2">
            <Grid item xs={6}>
              <ToggleButtonGroup
                value={pricePeriod}
                exclusive
                onChange={(e, value) => handlePricePeriodChange(value)}
                aria-label="rent period"
                fullWidth
              >
                <ToggleButton
                  value="Monthly"
                  className={`toggle-button ${pricePeriod === 'Monthly' ? 'selected' : ''}`}
                >
                  {t('propertyType.monthly')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
              <ToggleButtonGroup
                value={pricePeriod}
                exclusive
                onChange={(e, value) => handlePricePeriodChange(value)}
                aria-label="rent period"
                fullWidth
              >
                <ToggleButton
                  value="Daily"
                  className={`toggle-button ${pricePeriod === 'Daily' ? 'selected' : ''}`}
                >
                  {t('propertyType.daily')}
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </>
      )}

      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          className="button"
          onClick={handleNextClick}
          disabled={!propertyType || !transactionType || (transactionType === 'For Rent' && !pricePeriod)}
        >
          {t('propertyType.next')}
        </Button>
      </Box>
    </Container>
  );
};

export default PropertyType;
