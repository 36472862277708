import React, { useEffect } from 'react';
import styled from 'styled-components';
import Auth0Lock from 'auth0-lock';
import authConfig from '../auth_config.json';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const navigate = useNavigate();
  const redirectUri = window.location.origin.includes('localhost')
    ? 'http://localhost:3000'
    : 'https://revaluer.app';

  useEffect(() => {
    const lock = new Auth0Lock(
      authConfig.clientId,
      authConfig.domain,
      {
        auth: {
          redirectUrl: redirectUri,
          responseType: 'token id_token',
          audience: `https://${authConfig.domain}/userinfo`,
          params: {
            scope: 'openid profile email'
          }
        },
        allowedConnections: ['google-oauth2', 'Username-Password-Authentication'],
        additionalSignUpFields: [
          {
            name: "name",
            placeholder: "Enter your name"
          }
        ],
        socialButtonStyle: 'big',
        theme: {
          primaryColor: "#31324F",
          logo: "https://example.com/logo.png" // replace with your logo URL
        },
        languageDictionary: {
          title: "Revaluer.App"
        },
      }
    );

    lock.on('authenticated', (authResult) => {
      lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          console.error('Error getting user info:', error);
          return;
        }

        localStorage.setItem('accessToken', authResult.accessToken);
        localStorage.setItem('idToken', authResult.idToken);
        localStorage.setItem('profile', JSON.stringify(profile));

        navigate('/property-type');
      });
    });

    lock.show();
  }, [navigate, redirectUri]);

  return (
    <Container>
      <Content>
        <Title>Revaluer.App</Title>
        <Slogan>Every property is unique</Slogan>
        <LoginContainer id="auth0-login-container"></LoginContainer>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

const Content = styled.div`
  text-align: center;
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  max-width: 80%;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #333333;
`;

const Slogan = styled.p`
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  color: #555555;
  font-family: 'Poppins', sans-serif;
`;

const LoginContainer = styled.div`
  margin-top: 1.5rem;
`;

export default LoginPage;
