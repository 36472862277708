import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, FormControlLabel, Switch, ToggleButton, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useProperty } from '../context/DataContext';
import ScrollToTop from '../components/ScrollToTop';
import { useTranslation } from 'react-i18next';

const furnitureItems = [
  'Bathtub',
  'Bed',
  'Blinds',
  'Dining Table',
  'Fireplace',
  'Jakuzzi',
  'Kitchen Cabinets',
  'Shower Cabin',
  'Sofa Set',
  'Storage Cabinets',
  'Built-in Wardrobe'
];

const equipmentItems = [
  'Cable TV',
  'Clothes Dryer',
  'Dishwasher',
  'Intercom',
  'Internet',
  'Land Line Phone',
  'Range Hood',
  'Refrigerator',
  'Security Door',
  'Smart Home System',
  'TV',
  'Video Surveillance',
  'Washing Machine',
  'Burglar Alarm',
  'Hot Water Supply'
];

const heatingOptions = [
  'Other Central Heating Systems',
  'District Heating',
  'Electric Thermal Storage Heating',
  'Tiled Stove',
  'Norwegian Radiators',
  'Marble Radiators',
  'Gas Heating',
  'Floor and/or Wall Heating',
  'Heat Pump',
  'Solid Fuel Heating',
  'Fan Coil Unit'
];

const coolingOptionsApartment = [
  'Air Conditioner',
  'Central Cooling System in the Apartment',
  'Other Central Cooling Systems',
  'Fan Coil Unit',
  'Heat Pump'
];

const coolingOptionsHouse = [
  'Air Conditioner',
  'Central Cooling System',
  'Split/Ductless System or Fan Coil Unit',
  'Heat Pump'
];

const FurnitureEquipment = () => {
  const { t } = useTranslation();
  const { propertyData, updatePropertyData } = useProperty();
  const navigate = useNavigate();

  // Determine initial switch states based on context values
  const [includesFurniture, setIncludesFurniture] = useState(!!propertyData.furniture);
  const [furniture, setFurniture] = useState(propertyData.furniture || '');
  const [includesEquipment, setIncludesEquipment] = useState(
    equipmentItems.some(item => propertyData[item.toLowerCase().replace(/ /g, '_').replace(/-/g, '_')])
  );
  const [hasHeatingSystem, setHasHeatingSystem] = useState(!!propertyData.heating_system);
  const [heatingSystem, setHeatingSystem] = useState(propertyData.heating_system || '');
  const [hasCoolingSystem, setHasCoolingSystem] = useState(!!propertyData.cooling_system);
  const [coolingSystem, setCoolingSystem] = useState(propertyData.cooling_system || '');

  const handleToggleChange = (setter, key) => (event) => {
    const newValue = event.target.checked;
    setter(newValue);

    // Reset values if the switch is turned off
    if (!newValue) {
      if (key === 'hasHeatingSystem') {
        setHeatingSystem('');
        updatePropertyData({ heating_system: '' });
      } else if (key === 'hasCoolingSystem') {
        setCoolingSystem('');
        updatePropertyData({ cooling_system: '' });
      } else if (key === 'includesFurniture') {
        setFurniture('');
        updatePropertyData({ furniture: '' });
      } else if (key === 'includesEquipment') {
        equipmentItems.forEach((item) => {
          const itemKey = item.toLowerCase().replace(/ /g, '_').replace(/-/g, '_');
          updatePropertyData({ [itemKey]: false });
        });
      }
    }
  };

  const handleHeatingSystemChange = (option) => {
    setHeatingSystem(option);
    updatePropertyData({ heating_system: option });
  };

  const handleCoolingSystemChange = (option) => {
    setCoolingSystem(option);
    updatePropertyData({ cooling_system: option });
  };

  const handleNextClick = () => {
    if (!includesFurniture) {
      updatePropertyData({ furniture: 'Unfurnished' });
    } else {
      updatePropertyData({ furniture });
    }
    if (!hasHeatingSystem) {
      updatePropertyData({ heating_system: 'No Heating System' });
    }
    if (!hasCoolingSystem) {
      updatePropertyData({ cooling_system: 'No Cooling System' });
    }
    navigate('/amenities');
  };

  return (
    <Container maxWidth="md" className="container">
      <ScrollToTop />
      <Button onClick={() => navigate(-1)} variant="contained" color="secondary" style={{ marginBottom: '10px' }}>{t('furnitureEquipment.back')}</Button>
      <Typography variant="h5" component="h2" gutterBottom>
        {t('furnitureEquipment.title')}
      </Typography>
      <Box mt={4}>
        <FormControlLabel
          control={<Switch checked={includesFurniture} onChange={handleToggleChange(setIncludesFurniture, 'includesFurniture')} color="primary" />}
          label={t('furnitureEquipment.includesFurniture')}
        />
      </Box>
      {includesFurniture && (
        <>
          <Box mt={4}>
            <Typography variant="subtitle1">{t('furnitureEquipment.furnitureStatus')}</Typography>
            <Grid container spacing={1} justifyContent="center">
              {['Partially Furnished', 'Fully Furnished', 'Luxuriously Furnished'].map((status) => (
                <Grid item key={status} sx={{ mr: 1, mb: 1 }}>
                  <ToggleButton
                    value={status}
                    selected={furniture === status}
                    className={`toggle-button ${furniture === status ? 'selected' : ''}`}
                    onClick={() => {
                      setFurniture(status);
                      updatePropertyData({ furniture: status });
                    }}
                    sx={{ width: '100%' }}
                  >
                    {t(`furnitureEquipment.furnitureStatusOptions.${status}`)}
                  </ToggleButton>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box mt={4}>
            <Typography variant="subtitle1">{t('furnitureEquipment.selectFurniture')}</Typography>
            <Grid container spacing={1} justifyContent="center">
              {furnitureItems.map((item) => (
                <Grid item key={item} sx={{ mr: 1, mb: 1 }}>
                  <ToggleButton
                    value={item}
                    selected={propertyData[item.toLowerCase().replace(/ /g, '_').replace(/-/g, '_')] || false}
                    className={`toggle-button ${propertyData[item.toLowerCase().replace(/ /g, '_').replace(/-/g, '_')] ? 'selected' : ''}`}
                    onClick={() => {
                      const itemKey = item.toLowerCase().replace(/ /g, '_').replace(/-/g, '_');
                      const newState = !propertyData[itemKey];
                      updatePropertyData({ [itemKey]: newState });
                    }}
                    sx={{ width: '100%' }}
                  >
                    {t(`furnitureEquipment.furnitureItems.${item}`)}
                  </ToggleButton>
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      )}
      <Box mt={4}>
        <FormControlLabel
          control={<Switch checked={includesEquipment} onChange={handleToggleChange(setIncludesEquipment, 'includesEquipment')} color="primary" />}
          label={t('furnitureEquipment.includesEquipment')}
        />
      </Box>
      {includesEquipment && (
        <Box mt={4}>
          <Typography variant="subtitle1">{t('furnitureEquipment.selectEquipment')}</Typography>
          <Grid container spacing={1} justifyContent="center">
            {equipmentItems.map((item) => (
              <Grid item key={item} sx={{ mr: 1, mb: 1 }}>
                <ToggleButton
                  value={item}
                  selected={propertyData[item === 'Cable TV' ? 'cable_TV' : item.toLowerCase().replace(/ /g, '_').replace(/-/g, '_')] || false}
                  className={`toggle-button ${propertyData[item === 'Cable TV' ? 'cable_TV' : item.toLowerCase().replace(/ /g, '_').replace(/-/g, '_')] ? 'selected' : ''}`}
                  onClick={() => {
                    const itemKey = item === 'Cable TV' ? 'cable_TV' : item.toLowerCase().replace(/ /g, '_').replace(/-/g, '_');
                    const newState = !propertyData[itemKey];
                    updatePropertyData({ [itemKey]: newState });
                  }}
                  sx={{ width: '100%' }}
                >
                  {t(`furnitureEquipment.equipmentItems.${item}`)}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Box mt={4}>
        <FormControlLabel
          control={<Switch checked={hasHeatingSystem} onChange={handleToggleChange(setHasHeatingSystem, 'hasHeatingSystem')} color="primary" />}
          label={t('furnitureEquipment.hasHeatingSystem')}
        />
      </Box>
      {hasHeatingSystem && (
        <Box mt={4}>
          <Typography variant="subtitle1">{t('furnitureEquipment.selectHeatingSystem')}</Typography>
          <Grid container spacing={1} justifyContent="center">
            {heatingOptions.map((option) => (
              <Grid item key={option} sx={{ mr: 1, mb: 1 }}>
                <ToggleButton
                  value={option}
                  selected={heatingSystem === option}
                  className={`toggle-button ${heatingSystem === option ? 'selected' : ''}`}
                  onClick={() => handleHeatingSystemChange(option)}
                  sx={{ width: '100%' }}
                >
                  {t(`furnitureEquipment.heatingOptions.${option}`)}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Box mt={4}>
        <FormControlLabel
          control={<Switch checked={hasCoolingSystem} onChange={handleToggleChange(setHasCoolingSystem, 'hasCoolingSystem')} color="primary" />}
          label={t('furnitureEquipment.hasCoolingSystem')}
        />
      </Box>
      {hasCoolingSystem && (
        <Box mt={4}>
          <Typography variant="subtitle1">{t('furnitureEquipment.selectCoolingSystem')}</Typography>
          <Grid container spacing={1} justifyContent="center">
            {(propertyData.property_type === 'Apartment' ? coolingOptionsApartment : coolingOptionsHouse).map((option) => (
              <Grid item key={option} sx={{ mr: 1, mb: 1 }}>
                <ToggleButton
                  value={option}
                  selected={coolingSystem === option}
                  className={`toggle-button ${coolingSystem === option ? 'selected' : ''}`}
                  onClick={() => handleCoolingSystemChange(option)}
                  sx={{ width: '100%' }}
                >
                  {t(`furnitureEquipment.coolingOptions.${option}`)}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <Box mt={6}>
        <Button variant="contained" color="primary" onClick={handleNextClick}>
          {t('furnitureEquipment.next')}
        </Button>
      </Box>
    </Container>
  );
};

export default FurnitureEquipment;
