import React from 'react';
import { useProperty } from '../context/DataContext'; // Correct path
import { submitPropertyData } from '../services/apiService'; // Correct path
import { Button } from '@mui/material';

const SubmitButton = ({ t }) => {
  const { propertyData } = useProperty();

  const handleSubmit = async () => {
    try {
      console.log('Submitting property data:', propertyData); // Log the data being submitted
      const response = await submitPropertyData(propertyData);
      console.log('Property data submitted successfully:', response); // Log the response
      alert(t('transactionSpecifics.submitSuccess'));
    } catch (error) {
      console.error('Error submitting property data:', error); // Log the error
      alert(t('transactionSpecifics.submitFailure'));
    }
  };

  return (
    <Button variant="contained" color="secondary" onClick={handleSubmit} style={{ marginLeft: '10px' }}>
      {t('transactionSpecifics.submit')}
    </Button>
  );
};

export default SubmitButton;