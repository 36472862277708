import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Grid, ToggleButton, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useProperty } from '../context/DataContext';
import ScrollToTop from '../components/ScrollToTop';
import { useTranslation } from 'react-i18next';

const ParkingOptions = () => {
  const { t } = useTranslation();
  const { propertyData, updatePropertyData } = useProperty();
  const navigate = useNavigate();

  const parkingOptionsRent = [
    'Zone',
    'Outside of Zone',
    'Parking Available for Rent',
    'Private Parking Included in Rent',
    'Garage Available for Rent',
    'Garage Included in Rent',
    'Reserved Unmarked Parking Space'
  ];

  const parkingOptionsSale = [
    'Zone',
    'Outside of Zone',
    'Reserved Unmarked Parking Space',
    'Parking Spot for Sale',
    'Garage Spot for Sale',
    'Garage Included in Price',
    'Parking Included in Price'
  ];

  const [selectedParkingType, setSelectedParkingType] = useState(propertyData.parking_type || '');
  const [parkingCount, setParkingCount] = useState(propertyData.parking_count || '');
  const [garageCount, setGarageCount] = useState(propertyData.garage_count || '');

  const transactionType = propertyData.transaction_type;

  useEffect(() => {
    setSelectedParkingType(propertyData.parking_type || '');
    setParkingCount(propertyData.parking_count || '');
    setGarageCount(propertyData.garage_count || '');
  }, [propertyData]);

  const handleToggleChange = (option) => {
    const newSelectedParkingType = selectedParkingType === option ? '' : option;

    setSelectedParkingType(newSelectedParkingType);
    updatePropertyData({ parking_type: newSelectedParkingType });

    if (!['Parking Available for Rent', 'Private Parking Included in Rent', 'Reserved Unmarked Parking Space', 'Parking Spot for Sale', 'Parking Included in Price'].includes(newSelectedParkingType)) {
      setParkingCount('');
      updatePropertyData({ parking_count: '' });
    }

    if (!['Garage Spot for Sale', 'Garage Included in Price', 'Garage Available for Rent', 'Garage Included in Rent'].includes(newSelectedParkingType)) {
      setGarageCount('');
      updatePropertyData({ garage_count: '' });
    }
  };

  const handleParkingCountChange = (e) => {
    const value = e.target.value;
    setParkingCount(value);
    updatePropertyData({ parking_count: value });
  };

  const handleGarageCountChange = (e) => {
    const value = e.target.value;
    setGarageCount(value);
    updatePropertyData({ garage_count: value });
  };

  const handleNextClick = () => {
    navigate('/transaction-specifics');
  };

  const options = transactionType === 'For Rent' ? parkingOptionsRent : parkingOptionsSale;

  return (
    <Container maxWidth="md" className="container">
      <ScrollToTop /> {/* Scroll to top on component load */}
      <Button onClick={() => navigate(-1)} variant="contained" color="secondary" style={{ marginBottom: '10px' }}>
        {t('parkingOptions.back')}
      </Button>
      <Typography variant="h5" component="h2" gutterBottom>
        {t('parkingOptions.title')}
      </Typography>
      <Box mt={4}>
        <Typography variant="subtitle1">{t('parkingOptions.selectParkingOption')}</Typography>
        <Grid container spacing={1} justifyContent="center">
          {options.map((option) => (
            <Grid item key={option} sx={{ mr: 1, mb: 1 }}>
              <ToggleButton
                value={option}
                selected={selectedParkingType === option}
                className={`toggle-button ${selectedParkingType === option ? 'selected' : ''}`}
                onClick={() => handleToggleChange(option)}
                sx={{ width: '100%' }}
              >
                {t(`parkingOptions.parkingOptions.${option}`)}
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </Box>
      {['Parking Available for Rent', 'Private Parking Included in Rent', 'Reserved Unmarked Parking Space', 'Parking Spot for Sale', 'Parking Included in Price'].includes(selectedParkingType) && (
        <Box mt={4}>
          <TextField
            label={t('parkingOptions.numberOfParkingSpaces')}
            type="number"
            fullWidth
            value={parkingCount}
            onChange={handleParkingCountChange}
            margin="normal"
          />
        </Box>
      )}
      {['Garage Spot for Sale', 'Garage Included in Price', 'Garage Available for Rent', 'Garage Included in Rent'].includes(selectedParkingType) && (
        <Box mt={4}>
          <TextField
            label={t('parkingOptions.numberOfGarageSpaces')}
            type="number"
            fullWidth
            value={garageCount}
            onChange={handleGarageCountChange}
            margin="normal"
          />
        </Box>
      )}
      <Box mt={6}>
        <Button variant="contained" color="primary" onClick={handleNextClick}>
          {t('parkingOptions.next')}
        </Button>
      </Box>
    </Container>
  );
};

export default ParkingOptions;