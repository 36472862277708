import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Typography, Button, useMediaQuery, Box, Paper, CircularProgress } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CropIcon from '@mui/icons-material/Crop';
import ExploreIcon from '@mui/icons-material/Explore';
import InstagramIcon from '@mui/icons-material/Instagram';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ChairIcon from '@mui/icons-material/Chair';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import ReplayIcon from '@mui/icons-material/Replay'; // Import reset icon
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useProperty } from '../context/DataContext';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import '../styles/global.css';

const Sidebar = ({ activeColor = '#d4af37' }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { propertyData, resetPropertyData } = useProperty();
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const [valuationResponse, setValuationResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(); // Hook for translation

  const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
      let propName = parent ? `${parent}_${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  const triggerValuation = async () => {
    setLoading(true);
    try {
      const flattenedPropertyData = flattenObject(propertyData);
      const response = await axios.post('https://valuationapi.revaluer.app/predict_property_value', flattenedPropertyData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setValuationResponse(response.data[0]); // Assuming the response is an array
    } catch (error) {
      console.error('Error fetching valuation:', error);
    }
    setLoading(false);
  };

  const handleReset = () => {
    resetPropertyData();
    navigate('/property-type');
  };

  if (isSmallScreen) {
    return null; // Hide sidebar on small screens
  }

  const iconStyle = { fontSize: '2rem', marginRight: '4px', marginLeft: '2px' }; // Increase font size and adjust margin

  const showRevalueButton = !['/property-type', '/address-input'].includes(location.pathname);
  const showResetButton = ['/address-input'].includes(location.pathname) || showRevalueButton;
  const showValuationCard = showRevalueButton;

  const getListItemStyle = (path) => ({
    backgroundColor: location.pathname === path ? `${activeColor} !important` : 'transparent',
    color: location.pathname === path ? 'black !important' : 'inherit',
    '& .MuiListItemText-root': {
      color: location.pathname === path ? 'black !important' : 'inherit',
    },
    '&:hover': {
      backgroundColor: location.pathname === path ? `${activeColor} !important` : 'rgba(0, 0, 0, 0.08)',
      color: location.pathname === path ? 'black !important' : 'inherit',
      '& .MuiListItemText-root': {
        color: location.pathname === path ? 'black !important' : 'inherit',
      },
    },
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <div className="sidebar">
      <div className="sidebar-brand">
        <Box style={{ textAlign: 'center', padding: '15px' }}>
          <img
            src="/images/logohorizontal.png"
            alt="Revaluer.App"
            style={{ maxHeight: '120px', maxWidth: '100%', margin: '0 auto' }}
          />
        </Box>
      </div>
      <List>
        <ListItem button component={Link} to="/property-type" sx={getListItemStyle('/property-type')}>
          <ListItemIcon><HomeIcon sx={{ ...iconStyle, color: location.pathname === '/property-type' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.propertyType')} />
        </ListItem>
        <ListItem button component={Link} to="/address-input" sx={getListItemStyle('/address-input')}>
          <ListItemIcon><LocationOnIcon sx={{ ...iconStyle, color: location.pathname === '/address-input' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.addressInput')} />
        </ListItem>
        <ListItem button component={Link} to="/property-area" sx={getListItemStyle('/property-area')}>
          <ListItemIcon><CropIcon sx={{ ...iconStyle, color: location.pathname === '/property-area' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.propertyArea')} />
        </ListItem>
        <ListItem button component={Link} to="/property-structure" sx={getListItemStyle('/property-structure')}>
          <ListItemIcon><AccountTreeIcon sx={{ ...iconStyle, color: location.pathname === '/property-structure' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.propertyStructure')} />
        </ListItem>
        <ListItem button component={Link} to="/position-orientation-view" sx={getListItemStyle('/position-orientation-view')}>
          <ListItemIcon><ExploreIcon sx={{ ...iconStyle, color: location.pathname === '/position-orientation-view' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.positionOrientationView')} />
        </ListItem>
        <ListItem button component={Link} to="/property-condition" sx={getListItemStyle('/property-condition')}>
          <ListItemIcon><InstagramIcon sx={{ ...iconStyle, color: location.pathname === '/property-condition' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.propertyCondition')} />
        </ListItem>
        <ListItem button component={Link} to="/furniture-equipment" sx={getListItemStyle('/furniture-equipment')}>
          <ListItemIcon><ChairIcon sx={{ ...iconStyle, color: location.pathname === '/furniture-equipment' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.furnitureEquipment')} />
        </ListItem>
        <ListItem button component={Link} to="/amenities" sx={getListItemStyle('/amenities')}>
          <ListItemIcon><DomainAddIcon sx={{ ...iconStyle, color: location.pathname === '/amenities' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.amenities')} />
        </ListItem>
        <ListItem button component={Link} to="/parking-options" sx={getListItemStyle('/parking-options')}>
          <ListItemIcon><LocalParkingIcon sx={{ ...iconStyle, color: location.pathname === '/parking-options' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.parkingOptions')} />
        </ListItem>
        <ListItem button component={Link} to="/transaction-specifics" sx={getListItemStyle('/transaction-specifics')}>
          <ListItemIcon><PlagiarismIcon sx={{ ...iconStyle, color: location.pathname === '/transaction-specifics' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.transactionSpecifics')} />
        </ListItem>
        <ListItem button component={Link} to="/valuation" sx={getListItemStyle('/valuation')}>
          <ListItemIcon><AssessmentIcon sx={{ ...iconStyle, color: location.pathname === '/valuation' ? 'black' : 'inherit' }} /></ListItemIcon>
          <ListItemText primary={t('sidebar.valuation')} />
        </ListItem>
      </List>
      <Box mt={6} display="flex" flexDirection="column" alignItems="center">
        {showRevalueButton && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'white !important', // Custom color
              '&:hover': {
                backgroundColor: '#white !important', // Custom hover color
                color: '#d4af37 !important', // Icon color on hover
              },
              color: 'black !important',
              fontWeight: 'bold !important', // Make text bold
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={triggerValuation}
            startIcon={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EuroSymbolIcon sx={{ color: '#d4af37' }} />
              </Box>
            }
          >
            {t('sidebar.revalue')}
          </Button>
        )}
        {showValuationCard && (
          <Box mt={2} p={2} bgcolor="white" boxShadow={1} borderRadius={1} width="80%" textAlign="center">
            {loading ? (
              <CircularProgress />
            ) : valuationResponse ? (
              <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                {valuationResponse.estimated_value_per_m2 && (
                  <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    <strong>{t('sidebar.estimatedValue')}:</strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(Math.round(valuationResponse.estimated_value_per_m2))}
                  </Typography>
                )}
                {valuationResponse.transaction_adjusted_value && (
                  <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
                    <strong>{t('sidebar.transactionValue')}:</strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(Math.round(valuationResponse.transaction_adjusted_value))}
                  </Typography>
                )}
                {valuationResponse.model_file_mod_time && (
                  <Typography variant="body4" color="textSecondary" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
                    {t('sidebar.validForDate')}: {formatDate(valuationResponse.model_file_mod_time)}
                  </Typography>
                )}
              </Paper>
            ) : (
              <Typography variant="body1">{t('sidebar.valuationDisplay')}</Typography>
            )}
          </Box>
        )}
        {showResetButton && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: 'white !important', // Custom color
              '&:hover': {
                backgroundColor: '#white !important', // Custom hover color
                color: '#ee204d !important', // Icon color on hover
              },
              color: 'black !important',
              fontWeight: 'bold !important', // Make text bold
              mt: showValuationCard ? 4 : 0,
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handleReset}
            startIcon={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ReplayIcon sx={{ color: '#ee204d' }} />
              </Box>
            }
          >
            {t('sidebar.restart')}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default Sidebar;