import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';

const ValuationContext = createContext();

export const useValuation = () => {
  return useContext(ValuationContext);
};

export const ValuationProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [valuationResponse, setValuationResponse] = useState(null);

  const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
      let propName = parent ? `${parent}_${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  const handleValuation = async (propertyData) => {
    setLoading(true);
    try {
      const flattenedPropertyData = flattenObject(propertyData);
      const response = await axios.post('https://api.revaluer.app/predict_property_value', flattenedPropertyData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setValuationResponse(response.data);
    } catch (error) {
      console.error('Error fetching valuation:', error);
    }
    setLoading(false);
  };

  return (
    <ValuationContext.Provider value={{ loading, valuationResponse, handleValuation }}>
      {children}
    </ValuationContext.Provider>
  );
};
