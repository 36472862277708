import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useMediaQuery, Box } from '@mui/material';
import Sidebar from './components/Sidebar';
import MyAppBar from './components/AppBar';
import Layout from './components/Layout';
import PropertyType from './pages/PropertyType';
import AddressInput from './pages/AddressInput';
import PropertyStructure from './pages/PropertyStructure';
import PositionOrientationView from './pages/PositionOrientationView';
import PropertyArea from './pages/PropertyArea';
import PropertyCondition from './pages/PropertyCondition';
import Valuation from './pages/Valuation';
import FurnitureEquipment from './pages/FurnitureEquipment';
import Amenities from './pages/Amenities';
import ParkingOptions from './pages/ParkingOptions';
import TransactionSpecifics from './pages/TransactionSpecifics';
import LoginPage from './pages/LoginPage';
import { PropertyProvider } from './context/DataContext';
import { ValuationProvider } from './context/ValuationContext';
import { useAuth0 } from '@auth0/auth0-react';
import ProtectedRoute from './components/ProtectedRoute';

const AppContent = () => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect();
    return <div>Redirecting...</div>;
  }

  return (
    <Box className="layout">
      {isAuthenticated && <MyAppBar />}
      {isAuthenticated && !isMobile && <Sidebar />}
      <Box className={`main-content ${isMobile ? 'mobile' : ''}`}>
        <Layout>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/" element={<Navigate to="/property-type" />} />
            <Route path="/property-type" element={<ProtectedRoute element={<PropertyType />} />} />
            <Route path="/address-input" element={<ProtectedRoute element={<AddressInput />} />} />
            <Route path="/property-structure" element={<ProtectedRoute element={<PropertyStructure />} />} />
            <Route path="/position-orientation-view" element={<ProtectedRoute element={<PositionOrientationView />} />} />
            <Route path="/property-area" element={<ProtectedRoute element={<PropertyArea />} />} />
            <Route path="/property-condition" element={<ProtectedRoute element={<PropertyCondition />} />} />
            <Route path="/furniture-equipment" element={<ProtectedRoute element={<FurnitureEquipment />} />} />
            <Route path="/amenities" element={<ProtectedRoute element={<Amenities />} />} />
            <Route path="/parking-options" element={<ProtectedRoute element={<ParkingOptions />} />} />
            <Route path="/transaction-specifics" element={<ProtectedRoute element={<TransactionSpecifics />} />} />
            <Route path="/valuation" element={<ProtectedRoute element={<Valuation />} />} />
          </Routes>
        </Layout>
      </Box>
    </Box>
  );
};

const App = () => (
  <PropertyProvider>
    <ValuationProvider>
      <Router>
        <AppContent />
      </Router>
    </ValuationProvider>
  </PropertyProvider>
);

export default App;
