import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Grid, FormControl, InputLabel, OutlinedInput, Alert, Switch, FormControlLabel, ToggleButton, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useProperty } from '../context/DataContext';
import ScrollToTop from '../components/ScrollToTop'; // Make sure to update the path if needed
import { useTranslation } from 'react-i18next';
import '../styles/global.css'; // Ensure the path matches your project structure

const PropertyArea = () => {
  const { propertyData, updatePropertyData } = useProperty();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [propertyArea, setPropertyArea] = useState(propertyData.property_area || '');
  const [propertyAreaUnit, setPropertyAreaUnit] = useState(propertyData.property_area_unit || 'Square Meters (m2)');
  const [legalStatus, setLegalStatus] = useState(propertyData.legal_status || '');
  const [registeredArea, setRegisteredArea] = useState(propertyData.registered_property_area || '');
  const [lotType, setLotType] = useState(propertyData.lot_type || '');
  const [lotSize, setLotSize] = useState(propertyData.lot_size || '');
  const [lotSizeUnit, setLotSizeUnit] = useState(propertyData.lot_size_unit || '');
  const [numberOfOutbuildings, setNumberOfOutbuildings] = useState(propertyData.number_of_outbuildings || '');
  const [isFormValid, setIsFormValid] = useState(false); // State to manage form validation
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // Validate all required fields
    const isValid =
      propertyArea > 0 &&
      (propertyData.transaction_type === 'For Rent' || (
        legalStatus !== '' &&
        (legalStatus !== 'Partially Registered' || (registeredArea > 0 && registeredArea <= propertyArea)) &&
        (!isHouse || (lotType !== '' && lotSize > 0))
      ));

    setIsFormValid(isValid);
    setShowAlert(legalStatus === 'Partially Registered' && registeredArea > propertyArea);
  }, [propertyArea, legalStatus, registeredArea, lotType, lotSize, propertyData.transaction_type]);

  const handleNextClick = () => {
    updatePropertyData({
      property_area: propertyArea,
      property_area_unit: propertyAreaUnit,
      legal_status: legalStatus,
      registered_property_area: registeredArea,
      registered_property_area_unit: registeredArea ? propertyAreaUnit : '',
      lot_type: lotType,
      lot_size: lotSize,
      lot_size_unit: lotSize ? lotSizeUnit : '',
      number_of_outbuildings: numberOfOutbuildings,
    });
    navigate('/property-structure');
  };

  const handleLegalStatusChange = (status) => {
    setLegalStatus(status);
    updatePropertyData({ legal_status: status });
    if (status !== 'Partially Registered') {
      setRegisteredArea('');
      updatePropertyData({ registered_property_area: '', registered_property_area_unit: '' });
    }
  };

  useEffect(() => {
    if (registeredArea && propertyArea) {
      const legalStatusPercentage = (registeredArea / propertyArea) * 100;
      updatePropertyData({ legal_status_percentage: legalStatusPercentage });
    }
  }, [registeredArea, propertyArea]);

  const isHouse = propertyData.property_type === 'House';

  const lotTypes = [
    { value: 'Undeveloped', label: t('propertyArea.undeveloped') },
    { value: 'Landscaped Yard with Amenities', label: t('propertyArea.landscapedYard') },
    { value: 'Landscaped Flower Garden', label: t('propertyArea.flowerGarden') },
    { value: 'Orchard', label: t('propertyArea.orchard') },
    { value: 'Farmland', label: t('propertyArea.farmland') },
    { value: 'Forest', label: t('propertyArea.forest') },
    { value: 'Fishpond', label: t('propertyArea.fishpond') },
    { value: 'Other', label: t('propertyArea.other') }
  ];

  const lotSizeUnits = [
    { value: 'Square Meters (m2)', label: t('propertyArea.lotSizeUnits.squareMeters') },
    { value: 'Square Feet (sq ft)', label: t('propertyArea.lotSizeUnits.squareFeet') },
    { value: 'Ares (a)', label: t('propertyArea.lotSizeUnits.ares') },
    { value: 'Hectares (ha)', label: t('propertyArea.lotSizeUnits.hectares') },
    { value: 'Acres', label: t('propertyArea.lotSizeUnits.acres') },
    { value: 'Square Yards (sq yd)', label: t('propertyArea.lotSizeUnits.squareYards') },
    { value: 'Square Kilometers (km2)', label: t('propertyArea.lotSizeUnits.squareKilometers') },
    { value: 'Square Miles (sq mi)', label: t('propertyArea.lotSizeUnits.squareMiles') },
    { value: 'Rood', label: t('propertyArea.lotSizeUnits.rood') },
    { value: 'Cents', label: t('propertyArea.lotSizeUnits.cents') },
    { value: 'Bigha', label: t('propertyArea.lotSizeUnits.bigha') },
    { value: 'Guntha', label: t('propertyArea.lotSizeUnits.guntha') },
    { value: 'Ground', label: t('propertyArea.lotSizeUnits.ground') },
    { value: 'Jerib', label: t('propertyArea.lotSizeUnits.jerib') },
    { value: 'Dunam or Donum', label: t('propertyArea.lotSizeUnits.dunam') },
    { value: 'Mu', label: t('propertyArea.lotSizeUnits.mu') }
  ];

  const legalStatuses = [
    { value: 'Not Registered', label: t('propertyArea.notRegistered') },
    { value: 'Registration in Progress', label: t('propertyArea.registrationInProgress') },
    { value: 'Partially Registered', label: t('propertyArea.partiallyRegistered') },
    { value: 'Registered', label: t('propertyArea.registered') },
    { value: 'Legalization in Progress', label: t('propertyArea.legalizationInProgress') },
    { value: 'Registered with Transferable Non-Ownership Rights', label: t('propertyArea.registeredWithTransferableRights') }
  ];

  const handleUnitChange = (event) => {
    const unit = event.target.checked ? 'Square Feet (sq ft)' : 'Square Meters (m2)';
    setPropertyAreaUnit(unit);
    updatePropertyData({ property_area_unit: unit });
    if (legalStatus === 'Partially Registered') {
      updatePropertyData({ registered_property_area_unit: unit });
    }
  };

  return (
    <Container maxWidth="md" className="container">
      <ScrollToTop /> {/* Scroll to top on component load */}
      <Button onClick={() => navigate(-1)} variant="contained" color="secondary" style={{ marginBottom: '10px' }}>
        {t('propertyArea.back')}
      </Button>
      <Typography variant="h5" component="h2" gutterBottom textAlign="center">
        {t('propertyArea.pageTitle')}
      </Typography>
      <Box className="mt-2">
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6} md={4}>  {/* Reduced width for input field */}
            <FormControl fullWidth variant="outlined" sx={{ maxWidth: '200px', mx: 'auto' }}>
              <InputLabel htmlFor="property-area">{t('propertyArea.pageTitle')} ({propertyAreaUnit === 'Square Meters (m2)' ? t('propertyArea.lotSizeUnits.squareMeters') : t('propertyArea.lotSizeUnits.squareFeet')})</InputLabel>
              <OutlinedInput
                id="property-area"
                type="number"
                label={`${t('propertyArea.pageTitle')} (${propertyAreaUnit === 'Square Meters (m2)' ? t('propertyArea.lotSizeUnits.squareMeters') : t('propertyArea.lotSizeUnits.squareFeet')})`}
                value={propertyArea}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  setPropertyArea(value);
                  updatePropertyData({ property_area: value });
                  if (legalStatus === 'Partially Registered' && registeredArea > value) {
                    setShowAlert(true);
                    setIsFormValid(false);
                  } else {
                    setShowAlert(false);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>  {/* Reduced width for switch */}
            <Box textAlign="center">
              <Typography variant="body2" gutterBottom>
                {t('propertyArea.selectUnit')}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={propertyAreaUnit === 'Square Feet (sq ft)'}
                    onChange={handleUnitChange}
                    color="primary"
                  />
                }
                label={t('propertyArea.squareFeet')}
                labelPlacement="end"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {(propertyData.property_type === 'Apartment' || propertyData.property_type === 'House') && propertyData.transaction_type === 'For Sale' && (
        <Box className="mt-4">
          <Typography variant="h5" textAlign="center">{t('propertyArea.legalStatus')}</Typography>
          <Grid container spacing={2} justifyContent="center">
            {legalStatuses.map((status) => (
              <Grid item key={status.value} sx={{ mb: 1 }}>
                <ToggleButton
                  value={status.value}
                  selected={legalStatus === status.value}
                  onClick={() => handleLegalStatusChange(status.value)}
                  className={`toggleButton ${legalStatus === status.value ? 'selected' : ''}`}
                >
                  {status.label}
                </ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {propertyData.transaction_type === 'For Sale' && legalStatus === 'Partially Registered' && (
        <Box mt={4} textAlign="center">
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>  {/* Reduced width for input field */}
              <FormControl fullWidth variant="outlined" sx={{ maxWidth: '200px', mx: 'auto' }}>
                <InputLabel htmlFor="registered-area">{t('propertyArea.registeredArea')} ({propertyAreaUnit === 'Square Meters (m2)' ? t('propertyArea.lotSizeUnits.squareMeters') : t('propertyArea.lotSizeUnits.squareFeet')})</InputLabel>
                <OutlinedInput
                  id="registered-area"
                  type="number"
                  label={`${t('propertyArea.registeredArea')} (${propertyAreaUnit === 'Square Meters (m2)' ? t('propertyArea.lotSizeUnits.squareMeters') : t('propertyArea.lotSizeUnits.squareFeet')})`}
                  value={registeredArea}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value);
                    setRegisteredArea(value);
                    updatePropertyData({ registered_property_area: value });
                    if (value > propertyArea) {
                      setShowAlert(true);
                      setIsFormValid(false);
                    } else {
                      setShowAlert(false);
                      setIsFormValid(true);
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
          {showAlert && (
            <Alert severity="warning" className="mt-2">
              {t('propertyArea.registeredAreaWarning')}
            </Alert>
          )}
        </Box>
      )}
      {isHouse && (
        <>
          <Box mt={4} textAlign="center">
            <Typography variant="h5" textAlign="center">{t('propertyArea.lotType')}</Typography>
            <Grid container spacing={2} justifyContent="center">
              {lotTypes.map((type) => (
                <Grid item key={type.value} sx={{ mb: 1 }}>
                  <ToggleButton
                    value={type.value}
                    selected={lotType === type.value}
                    onClick={() => {
                      setLotType(type.value);
                      updatePropertyData({ lot_type: type.value });
                    }}
                    className={`toggleButton ${lotType === type.value ? 'selected' : ''}`}
                  >
                    {type.label}
                  </ToggleButton>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box mt={4} textAlign="center">
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={4}>  {/* Reduced width for input field */}
                <FormControl fullWidth variant="outlined" sx={{ maxWidth: '200px', mx: 'auto' }}>
                  <InputLabel htmlFor="lot-size">{t('propertyArea.lotSize')}</InputLabel>
                  <OutlinedInput
                    id="lot-size"
                    type="number"
                    label={t('propertyArea.lotSize')}
                    value={lotSize}
                    onChange={(e) => {
                      const value = parseFloat(e.target.value);
                      setLotSize(value);
                      updatePropertyData({ lot_size: value });
                      if (!value) {
                        setLotSizeUnit('');
                        updatePropertyData({ lot_size_unit: '' });
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>  {/* Reduced width for dropdown */}
                <FormControl fullWidth variant="outlined" sx={{ maxWidth: '200px', mx: 'auto' }}>
                  <InputLabel htmlFor="lot-size-unit">{t('propertyArea.unit')}</InputLabel>
                  <Select
                    id="lot-size-unit"
                    value={lotSizeUnit}
                    onChange={(e) => {
                      const unit = e.target.value;
                      setLotSizeUnit(unit);
                      updatePropertyData({ lot_size_unit: unit });
                    }}
                    label={t('propertyArea.unit')}
                  >
                    {lotSizeUnits.map(unit => (
                      <MenuItem key={unit.value} value={unit.value}>
                        {unit.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Box mt={4} textAlign="center">
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={6} md={4}>  {/* Reduced width for input field */}
                <FormControl fullWidth variant="outlined" sx={{ maxWidth: '200px', mx: 'auto' }}>
                  <InputLabel htmlFor="number-of-outbuildings">{t('propertyArea.numberOfOutbuildings')}</InputLabel>
                  <OutlinedInput
                    id="number-of-outbuildings"
                    type="number"
                    label={t('propertyArea.numberOfOutbuildings')}
                    value={numberOfOutbuildings}
                    onChange={(e) => {
                      setNumberOfOutbuildings(e.target.value);
                      updatePropertyData({ number_of_outbuildings: e.target.value });
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Box mt={4} textAlign="center">
        <Button variant="contained" color="primary" className="next-button" onClick={handleNextClick} disabled={!isFormValid}>
          {t('propertyArea.next')}
        </Button>
      </Box>
    </Container>
  );
};

export default PropertyArea;