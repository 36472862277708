import React, { useState, useEffect, useRef } from 'react';
import { Container, Box, Typography, Button, Switch, FormControlLabel, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '@geoapify/geocoder-autocomplete/styles/minimal.css';
import { GeocoderAutocomplete } from '@geoapify/geocoder-autocomplete';
import { useProperty } from '../context/DataContext';
import { getNearByPlaces, getSatelliteImagery, getWalkScore, getOSMCounts, performReverseGeocoding } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import L from 'leaflet';
import axios from 'axios';
import ScrollToTop from '../components/ScrollToTop';
import { useAuth0 } from '@auth0/auth0-react'; // Import Auth0
import { useTranslation } from 'react-i18next'; // Import useTranslation

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const AddressInput = ({ processingMessageFontSizeLg = '1rem' }) => {
  const { t } = useTranslation(); // Initialize translation hook
  const { updatePropertyData, propertyData } = useProperty();
  const { user } = useAuth0(); // Get user from Auth0
  const navigate = useNavigate();
  const [address, setAddress] = useState(propertyData.address || '');
  const [position, setPosition] = useState(propertyData.position || [44.787197, 20.457273]); // Centered over Belgrade
  const [debug, setDebug] = useState(false);
  const [reverseGeocodeData, setReverseGeocodeData] = useState(propertyData.reverseGeocodeData || null);
  const [nearbyPlaces, setNearbyPlaces] = useState(propertyData.nearbyPlaces || {});
  const [osmCounts, setOsmCounts] = useState(propertyData.osmCounts || {});
  const [satelliteImage, setSatelliteImage] = useState(propertyData.satelliteImage || null);
  const [walkScore, setWalkScore] = useState(propertyData.walkScore || null);
  const [zoom, setZoom] = useState(propertyData.position ? 18 : 13); // Use 18 if position exists, otherwise 13
  const [loading, setLoading] = useState(false);
  const [useGoogleNearby, setUseGoogleNearby] = useState(propertyData.useGoogleNearby || true); // Default set to false
  const [satelliteImageResponse, setSatelliteImageResponse] = useState(propertyData.satelliteImageResponse || null);
  const [showInstructions, setShowInstructions] = useState(false); // New state for instructions visibility
  const [autocompleteVisible, setAutocompleteVisible] = useState(true); // New state for autocomplete visibility
  const [showNextButton, setShowNextButton] = useState(false); // New state for showing the next button
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog open
  const [processingMessage, setProcessingMessage] = useState(t('addressInput.locationBeingAnalyzed')); // State for processing message

  const adminEmails = process.env.REACT_APP_ADMIN_EMAILS ? process.env.REACT_APP_ADMIN_EMAILS.split(',') : [];
  const apiKey = process.env.REACT_APP_GEOAPIFY_KEY; 

  const geocoderContainerRef = useRef(null);
  const timeoutRef = useRef(null); // Ref to store timeout ID
  const abortController = useRef(new AbortController());

  useEffect(() => {
    const el = geocoderContainerRef.current;
    if (el && !el.geocoder) {
      el.className = 'relative minimal round-borders';

      const autocomplete = new GeocoderAutocomplete(el, apiKey, {
        placeholder: t('addressInput.enterAddress'),
      });

      autocomplete.on('select', async (location) => {
        if (location) {
          const { lat, lon } = location.properties;
          setPosition([lat, lon]);
          setZoom(18); // Set the zoom level to 18
          const reverseData = await performReverseGeocoding(lat, lon);
          setReverseGeocodeData(reverseData);
          setAddress(location.properties.formatted);
          setShowInstructions(true); // Show instructions after selecting an address
          setAutocompleteVisible(false); // Hide the autocomplete box
        } else {
          setAddress('');
          setPosition([44.787197, 20.457273]); // Reset to default position
          setZoom(13); // Reset to default zoom
          setReverseGeocodeData(null);
          setShowInstructions(false); // Hide instructions if no address is selected
          setAutocompleteVisible(true); // Show the autocomplete box
        }
      });

      el.geocoder = autocomplete; // Mark as initialized
    }
  }, [apiKey, t]);

  useEffect(() => {
    if (address !== propertyData.address) {
      updatePropertyData({ address });
    }
  }, [address, propertyData.address, updatePropertyData]);

  useEffect(() => {
    if (JSON.stringify(position) !== JSON.stringify(propertyData.position)) {
      updatePropertyData({ position });
    }
  }, [position, propertyData.position, updatePropertyData]);

  useEffect(() => {
    if (JSON.stringify(reverseGeocodeData) !== JSON.stringify(propertyData.reverseGeocodeData)) {
      updatePropertyData({ reverseGeocodeData });
    }
  }, [reverseGeocodeData, propertyData.reverseGeocodeData, updatePropertyData]);

  useEffect(() => {
    if (JSON.stringify(nearbyPlaces) !== JSON.stringify(propertyData.nearbyPlaces)) {
      updatePropertyData({ nearbyPlaces });
    }
  }, [nearbyPlaces, propertyData.nearbyPlaces, updatePropertyData]);

  useEffect(() => {
    if (JSON.stringify(osmCounts) !== JSON.stringify(propertyData.osmCounts)) {
      updatePropertyData({ osmCounts });
    }
  }, [osmCounts, propertyData.osmCounts, updatePropertyData]);

  useEffect(() => {
    if (satelliteImage !== propertyData.satelliteImage) {
      updatePropertyData({ satelliteImage });
    }
  }, [satelliteImage, propertyData.satelliteImage, updatePropertyData]);

  useEffect(() => {
    if (JSON.stringify(walkScore) !== JSON.stringify(propertyData.walkScore)) {
      updatePropertyData({ walkScore });
    }
  }, [walkScore, propertyData.walkScore, updatePropertyData]);

  useEffect(() => {
    if (JSON.stringify(satelliteImageResponse) !== JSON.stringify(propertyData.satelliteImageResponse)) {
      updatePropertyData({ satelliteImageResponse });
    }
  }, [satelliteImageResponse, propertyData.satelliteImageResponse, updatePropertyData]);

  const toggleDebug = () => setDebug(!debug);
  const toggleGoogleNearby = () => setUseGoogleNearby(!useGoogleNearby);

  const handleMarkerDrag = async (e) => {
    const latlng = e.target.getLatLng();
    setPosition([latlng.lat, latlng.lng]);
    const reverseData = await performReverseGeocoding(latlng.lat, latlng.lng);
    setReverseGeocodeData(reverseData);
  };

  const handleConfirmLocation = async () => {
    if (reverseGeocodeData) {
      const { lat, lng, formatted } = reverseGeocodeData;
      setLoading(true);
      clearTimeout(timeoutRef.current); // Clear any existing timeout
      timeoutRef.current = setTimeout(() => setDialogOpen(true), 60000); // Open dialog after 60 seconds if loading is still true

      try {
        const [nearbyPlacesData, walkScoreData, satelliteImageData, osmCountsData] = await Promise.all([
          getNearByPlaces(lat, lng, useGoogleNearby, { signal: abortController.current.signal }).catch(() => ({})),
          getWalkScore(lat, lng, formatted, { signal: abortController.current.signal }).catch(() => ({ walkscore: null, description: null, transit: null, bike: null })),
          getSatelliteImagery(lat, lng, 18, { signal: abortController.current.signal }).catch(() => null),
          getOSMCounts(lat, lng, { signal: abortController.current.signal }).catch(() => ({})),
        ]);

        setNearbyPlaces(nearbyPlacesData);
        setWalkScore(walkScoreData);
        setSatelliteImage(satelliteImageData);
        setOsmCounts(osmCountsData);

        // Send satellite image to analysis API
        const imageBlob = b64toBlob(satelliteImageData, 'image/png');
        const formData = new FormData();
        formData.append('images', imageBlob, 'satellite.png');

        try {
          const response = await axios.post('https://imageapi.revaluer.app/satellite_image_analysis', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            signal: abortController.current.signal,
          });

          const satelliteAnalysisData = response.data;
          setSatelliteImageResponse(satelliteAnalysisData);

          updatePropertyData({
            ...reverseGeocodeData,
            lat,
            lng,
            nearby_places: nearbyPlacesData,
            walk_score: walkScoreData.walkscore || null,
            osm_counts: osmCountsData,
            satellite_image_analysis: satelliteAnalysisData,
          });
        } catch (imageError) {
          if (imageError.name !== 'AbortError') {
            console.error('Error fetching satellite image analysis data:', imageError);
          }
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error fetching additional data:', error);
        }
      } finally {
        setLoading(false);
        setShowNextButton(true); // Show the next button after processing
        clearTimeout(timeoutRef.current); // Clear timeout after processing is done
      }
    }
  };

  const handleDialogClose = (proceedWithoutInfo) => {
    setDialogOpen(false);
    abortController.current.abort(); // Abort all ongoing requests
    if (proceedWithoutInfo) {
      updatePropertyData({ address: null, position: null });
      navigate('/property-area');
    } else {
      setLoading(false);
      setShowNextButton(false);
    }
  };

  const LocationMarker = () => {
    const map = useMap();

    useEffect(() => {
      map.setView(position, zoom);
    }, [map, position, zoom]);

    return <Marker position={position} draggable eventHandlers={{ dragend: handleMarkerDrag }} />;
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  return (
    <Container maxWidth="sm" className="address-input-box" style={{ textAlign: 'center', marginTop: '5px' }}>
      <ScrollToTop /> {/* Scroll to top on component load */}
      <Button onClick={() => navigate(-1)} variant="contained" color="secondary" style={{ marginBottom: '10px', marginTop: '5px' }}>
        {t('addressInput.back')}
      </Button>
      <Typography variant="h5" gutterBottom className="page-title">{t('addressInput.pageTitle')}</Typography>
      <Box mt={2} className="geocoder-container">
        {autocompleteVisible && (
          <div id="geocoder-container" ref={geocoderContainerRef}></div>
        )}
      </Box>
      {showInstructions && ( // Display instructions if showInstructions is true
        <Box mt={2}>
          <Typography variant="body2" color="error" style={{ fontWeight: 'bold', marginTop: '20px' }}>
            {t('addressInput.confirmLocationInstructions')}
          </Typography>
        </Box>
      )}
      <Box mt={2} className="map-container">
        <MapContainer center={position} zoom={zoom} style={{ height: '400px', width: '100%', zIndex: 0 }}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <LocationMarker />
        </MapContainer>
      </Box>
      <Box mt={2} className="confirm-button">
        {!showNextButton && !loading && (
          <Button variant="contained" color="primary" onClick={handleConfirmLocation} disabled={loading}>
            {t('addressInput.confirmLocation')}
          </Button>
        )}
        {showNextButton && !loading && (
          <Button variant="contained" color="primary" onClick={() => navigate('/property-area')}>
            {t('addressInput.next')}
          </Button>
        )}
      </Box>
      {loading && (
        <Box mt={1} display="flex" flexDirection="column" alignItems="center">
          <CircularProgress size={20} />
          <Typography variant="body2" color="textSecondary" mt={1} sx={{ fontSize: { xs: '0.8rem', lg: processingMessageFontSizeLg }, fontWeight: 'bold', color: '#333' }}>
            {processingMessage}
          </Typography>
        </Box>
      )}
      {user && adminEmails.includes(user.email) && (
        <>
          <Box mt={4} className="switch-container">
            <FormControlLabel
              control={<Switch checked={useGoogleNearby} onChange={toggleGoogleNearby} color="primary" />}
              label={t('addressInput.toggleGoogleNearby')}
            />
            {/* To set the default to false, just update the useGoogleNearby state in the initial declaration */}
          </Box>
          <Box mt={4} className="switch-container">
            <FormControlLabel control={<Switch checked={debug} onChange={toggleDebug} color="primary" />} label={t('addressInput.toggleDebug')} />
          </Box>
        </>
      )}
      {debug && (
        <Box mt={2} style={{ textAlign: 'left', wordWrap: 'break-word' }}>
          <Typography variant="body1" component="p"><strong>Geocoded Address:</strong> {address}</Typography>
          <Typography variant="body1" component="p"><strong>lat:</strong> {position[0]}</Typography>
          <Typography variant="body1" component="p"><strong>lng:</strong> {position[1]}</Typography>
          {reverseGeocodeData && (
            <>
              <Typography variant="body1" component="p"><strong>country:</strong> {reverseGeocodeData.country}</Typography>
              <Typography variant="body1" component="p"><strong>region:</strong> {reverseGeocodeData.region}</Typography>
              <Typography variant="body1" component="p"><strong>state:</strong> {reverseGeocodeData.state}</Typography>
              <Typography variant="body1" component="p"><strong>state_district:</strong> {reverseGeocodeData.state_district}</Typography>
              <Typography variant="body1" component="p"><strong>county:</strong> {reverseGeocodeData.county}</Typography>
              <Typography variant="body1" component="p"><strong>city:</strong> {reverseGeocodeData.city}</Typography>
              <Typography variant="body1" component="p"><strong>municipality:</strong> {reverseGeocodeData.municipality}</Typography>
              <Typography variant="body1" component="p"><strong>city_importance:</strong> {reverseGeocodeData.city_importance}</Typography>
              <Typography variant="body1" component="p"><strong>city_popularity:</strong> {reverseGeocodeData.city_popularity}</Typography>
              <Typography variant="body1" component="p"><strong>town:</strong> {reverseGeocodeData.town}</Typography>
              <Typography variant="body1" component="p"><strong>village:</strong> {reverseGeocodeData.village}</Typography>
              <Typography variant="body1" component="p"><strong>district:</strong> {reverseGeocodeData.district}</Typography>
              <Typography variant="body1" component="p"><strong>neighbourhood:</strong> {reverseGeocodeData.neighbourhood}</Typography>
              <Typography variant="body1" component="p"><strong>suburb:</strong> {reverseGeocodeData.suburb}</Typography>
              <Typography variant="body1" component="p"><strong>quarter:</strong> {reverseGeocodeData.quarter}</Typography>
              <Typography variant="body1" component="p"><strong>city_block:</strong> {reverseGeocodeData.city_block}</Typography>
              <Typography variant="body1" component="p"><strong>road:</strong> {reverseGeocodeData.road}</Typography>
              <Typography variant="body1" component="p"><strong>street:</strong> {reverseGeocodeData.street}</Typography>
              <Typography variant="body1" component="p"><strong>street_importance:</strong> {reverseGeocodeData.street_importance}</Typography>
              <Typography variant="body1" component="p"><strong>street_popularity:</strong> {reverseGeocodeData.street_popularity}</Typography>
              <Typography variant="body1" component="p"><strong>street_number:</strong> {reverseGeocodeData.street_number}</Typography>
              <Typography variant="body1" component="p"><strong>subdivision:</strong> {reverseGeocodeData.subdivision}</Typography>
              <Typography variant="body1" component="p"><strong>amenity_importance:</strong> {reverseGeocodeData.amenity_importance}</Typography>
              <Typography variant="body1" component="p"><strong>amenity_popularity:</strong> {reverseGeocodeData.amenity_popularity}</Typography>
              <Typography variant="body1" component="p"><strong>formatted:</strong> {reverseGeocodeData.formatted}</Typography>
              <Typography variant="body1" component="p"><strong>city_distance:</strong> {reverseGeocodeData.city_distance}</Typography>
              <Typography variant="body1" component="p"><strong>street_distance:</strong> {reverseGeocodeData.street_distance}</Typography>
              <Typography variant="body1" component="p"><strong>amenity_distance:</strong> {reverseGeocodeData.amenity_distance}</Typography>
            </>
          )}
          {nearbyPlaces && (
            <>
              <Typography variant="body1" component="p"><strong>Nearby Places Count:</strong></Typography>
              {Object.keys(nearbyPlaces).map((key) => (
                <Typography key={key} variant="body1" component="p">{key}: {nearbyPlaces[key]}</Typography>
              ))}
            </>
          )}
          {osmCounts && (
            <>
              <Typography variant="body1" component="p"><strong>OSM Counts:</strong></Typography>
              {Object.keys(osmCounts).map((key) => (
                <Typography key={key} variant="body1" component="p">{key}: {osmCounts[key]}</Typography>
              ))}
            </>
          )}
          {walkScore && (
            <>
              <Typography variant="body1" component="p"><strong>Walk Score:</strong> {walkScore.walkscore}</Typography>
              <Typography variant="body1" component="p"><strong>Description:</strong> {walkScore.description}</Typography>
              {walkScore.transit && <Typography variant="body1" component="p"><strong>Transit Score:</strong> {walkScore.transit.score}</Typography>}
              {walkScore.bike && <Typography variant="body1" component="p"><strong>Bike Score:</strong> {walkScore.bike.score}</Typography>}
            </>
          )}
          {satelliteImage && (
            <>
              <Typography variant="body1" component="p"><strong>Satellite Image:</strong></Typography>
              <img src={`data:image/png;base64,${satelliteImage}`} alt="Satellite view" />
              {satelliteImageResponse && (
                <>
                  <Typography variant="body1" component="p"><strong>Satellite Image Analysis:</strong></Typography>
                  <pre>{JSON.stringify(satelliteImageResponse, null, 2)}</pre>
                </>
              )}
            </>
          )}
        </Box>
      )}
      <Dialog open={dialogOpen} onClose={() => handleDialogClose(false)}>
        <DialogTitle>{t('addressInput.locationAnalysisTakingTooLong')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('addressInput.locationAnalysisTakingTooLongDescription')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(true)} color="primary">{t('addressInput.proceedWithoutInfo')}</Button>
          <Button onClick={() => { setDialogOpen(false); handleConfirmLocation(); }} color="primary" autoFocus>{t('addressInput.retry')}</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AddressInput;