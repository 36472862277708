import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  Avatar,
  Typography,
  Tooltip,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Paper,
  Select,
  Divider
} from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import ReplayIcon from '@mui/icons-material/Replay';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProperty } from '../context/DataContext';
import { useValuation } from '../context/ValuationContext';
import axios from 'axios';
import '../styles/global.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

const Appbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { propertyData, resetPropertyData } = useProperty();
  const { handleValuation } = useValuation();
  const [valuationResponse, setValuationResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 600px)');
  const { user, logout } = useAuth0(); // Get user and logout from Auth0
  const [currentTheme, setCurrentTheme] = useState('light');
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.className = currentTheme;
  }, [currentTheme]);

  const toggleTheme = () => {
    setCurrentTheme(currentTheme === 'light' ? 'dark' : 'light');
  };

  const flattenObject = (obj, parent = '', res = {}) => {
    for (let key in obj) {
      let propName = parent ? `${parent}_${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        flattenObject(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  };

  const triggerValuation = async () => {
    setLoading(true);
    try {
      const flattenedPropertyData = flattenObject(propertyData);
      const response = await axios.post('https://valuationapi.revaluer.app/predict_property_value', flattenedPropertyData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setValuationResponse(response.data[0]);
      setOpenModal(true);
    } catch (error) {
      console.error('Error fetching valuation:', error);
    }
    setLoading(false);
  };

  const handleReset = () => {
    resetPropertyData();
    navigate('/property-type');
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const showRevalueButton = !['/property-type', '/address-input'].includes(location.pathname);
  const showResetButton = location.pathname === '/address-input' || !['/property-type'].includes(location.pathname);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawerItems = (
    <Box
      sx={{
        width: 250,
        padding: 2,
        backgroundColor: '#f5f5f5',
        height: '100%',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={toggleTheme}>
          <ListItemIcon><Brightness4Icon /></ListItemIcon>
          <ListItemText primary="Toggle Theme" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Select
              value={i18n.language}
              onChange={(e) => i18n.changeLanguage(e.target.value)}
              variant="outlined"
              size="small"
              displayEmpty
              sx={{
                color: 'black',
                backgroundColor: 'white',
                '.MuiSelect-icon': { color: 'black' },
                minWidth: 100
              }}
            >
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="sr">Српски</MenuItem>
              <MenuItem value="de">Deutsch</MenuItem>
              <MenuItem value="es">Español</MenuItem>
            </Select>
          </ListItemIcon>
        </ListItem>
        <Divider />
        <ListItem button onClick={() => logout({ returnTo: window.location.origin })}>
          <ListItemIcon>
            <Avatar src={user.picture} alt="Profile Picture" sx={{ width: '30px', height: '30px' }} />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="sticky" className={`appbar ${isSmallScreen ? 'appbar-small' : 'appbar-large'} ${currentTheme}`}>
        <Toolbar className="appbar-toolbar">
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <IconButton color="inherit" onClick={toggleDrawer(true)} sx={{ fontSize: '1rem', marginRight: '0px', marginLeft: '-5px' }}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
              {drawerItems}
            </Drawer>
            {isSmallScreen && (
              <Box display="flex" alignItems="center" gap={1}> {/* Reduced gap for compact layout */}
                {showResetButton && (
                  <Tooltip title="Restart">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'var(--button-bg-color) !important',
                        '&:hover': {
                          backgroundColor: 'var(--button-hover-bg-color) !important',
                        },
                        color: 'white !important',
                        fontWeight: 'bold !important',
                        minWidth: 'auto',
                        padding: '0 10px'
                      }}
                      startIcon={<ReplayIcon sx={{ color: '#ee204d', fontSize: '1.5rem' }} />}
                      onClick={handleReset}
                    >
                      Restart
                    </Button>
                  </Tooltip>
                )}
                {showRevalueButton && (
                  <Tooltip title="Revalue">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: 'var(--button-bg-color) !important',
                        '&:hover': {
                          backgroundColor: 'var(--button-hover-bg-color) !important',
                        },
                        color: 'white !important',
                        fontWeight: 'bold !important',
                        minWidth: 'auto',
                        padding: '0 10px'
                      }}
                      startIcon={<EuroSymbolIcon sx={{ color: '#d4af37', fontSize: '1.5rem' }} />}
                      onClick={triggerValuation}
                    >
                      Revalue
                    </Button>
                  </Tooltip>
                )}
              </Box>
            )}
            {user && (
              <Tooltip title={user.name}>
                <IconButton onClick={handleAvatarClick}>
                  {user.picture ? (
                    <Avatar src={user.picture} alt="Profile Picture" sx={{ width: '30px', height: '30px' }} />
                  ) : (
                    <Avatar alt={user.name} sx={{ width: '30px', height: '30px' }}>{user.name[0]}</Avatar>
                  )}
                </IconButton>
              </Tooltip>
            )}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      {isSmallScreen && (
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            sx: {
              margin: '20px',
              padding: '20px',
              borderRadius: '15px',
            },
          }}
        >
          <DialogTitle sx={{ paddingBottom: '0' }}>Valuation Results</DialogTitle>
          <DialogContent sx={{ paddingTop: '0' }}>
            {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100px">
                <CircularProgress />
              </Box>
            ) : valuationResponse ? (
              <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px', textAlign: 'center' }}>
                {valuationResponse.estimated_value_per_m2 && (
                  <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                    <strong>Estimated Value per m²:</strong><br />
                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(Math.round(valuationResponse.estimated_value_per_m2))}
                  </Typography>
                )}
                {valuationResponse.transaction_adjusted_value && (
                  <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                    <strong>Transaction Adjusted Value:</strong><br />
                    {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(Math.round(valuationResponse.transaction_adjusted_value))}
                  </Typography>
                )}
              </Paper>
            ) : (
              <Typography variant="body1">Valuation will be displayed here</Typography>
            )}
          </DialogContent>
          <DialogActions sx={{ padding: '16px' }}>
            <Button onClick={handleCloseModal} variant="contained" color="primary" fullWidth>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default Appbar;
