import React from 'react';
import { Box, Container, useMediaQuery } from '@mui/material';
import ScrollToTop from '../components/ScrollToTop'; // Make sure to update the path if needed
import '../styles/global.css';

const Layout = ({ children }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Container maxWidth="lg" className={`main-content ${isMobile ? 'mobile' : ''}`}>
      <ScrollToTop />
      {children}
    </Container>
  );
};

export default Layout;
