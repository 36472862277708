import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import App from './App';
import './styles/global.css';
import reportWebVitals from './reportWebVitals';
import authConfig from './auth_config.json';
import './i18n'; // Import i18n configuration

const domain = process.env.REACT_APP_AUTH0_DOMAIN || authConfig.domain;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || authConfig.clientId;
const redirectUri = process.env.REACT_APP_AUTH0_REDIRECT_URI;

const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{ redirect_uri: redirectUri }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <App />
      </LocalizationProvider>
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();
